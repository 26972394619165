import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const ButtonMainStyle = styled("div")`
  background-color: var(--blue-color);
  color: var(--white-color);
  padding: 5px 15px;
  border-radius: 4px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;

  @media (min-width: ${Screen.web}) {
    padding: 10px;
  }
`;
