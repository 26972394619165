import React, { useState, useEffect } from "react";

export interface Github {
  sha: string;
  commit: {
    message: string;
    author: {
      name: string;
    };
    verification: {
      verified: boolean;
      reason: string;
    };
  };
}

interface CommitData {
  data: Github[];
  loading: boolean;
}

function useGetCommits(): CommitData {
  const [data, setData] = useState<Github[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const apiUrl = process.env.REACT_APP_API_URL; //token
  useEffect(() => {
    const getData = async () => {
      const owner = "krakowdeveloper";
      const repo = "portfolio";

      const url = `https://api.github.com/repos/${owner}/${repo}/commits?per_page=10`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `token ${apiUrl}`,
            "X-GitHub-Api-Version": "2022-11-28",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch commits");
        }

        const data = await response.json();

        setData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching commits:", error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { data, loading };
}

export default useGetCommits;
