import React from "react";

import { TooltipStyle } from "./StyleTooltip";
interface TooltipProps {
  position: string;
  children: React.ReactNode;
  display: boolean;
  title: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  position,
  children,
  display,
  title,
}) => {
  return (
    <TooltipStyle>
      {display && (
        <div
          className={position === "right" ? "Tooltip-right" : "Tooltip-bottom"}
        >
          <h2>{title}:</h2>
          {children}
        </div>
      )}
    </TooltipStyle>
  );
};

export default Tooltip;
