import React, { useState } from "react";
import { ContentStyle } from "./contentStyle";
import ButtonMain from "components/buttonMain/ButtonMain";
import { WevdevIcon, NpmLibrary } from "icons/dashboard";
import AboutMe from "components/about/about";
import Tooltip from "components/Tooltips/Tooltip";

const initial = [
  {
    id: 1,
    open: false,
  },
  {
    id: 2,
    open: false,
  },
  {
    id: 3,
    open: false,
  },
  {
    id: 4,
    open: false,
  },
];

interface ControlTooltip {
  open: boolean;
  id: number;
}

function Content() {
  const [display, setDisplay] = useState<ControlTooltip[]>(initial);

  const handleMouseEnter = (id: number) => {
    setDisplay((prev) =>
      prev.map((item) => (item.id === id ? { ...item, open: true } : item))
    );
  };

  const handleMouseLeave = (id: number) => {
    setDisplay((prev) =>
      prev.map((item) => (item.id === id ? { ...item, open: false } : item))
    );
  };

  return (
    <ContentStyle>
      <AboutMe />
      <h4>Recent projects:</h4>
      <ul className="list-projects">
        <li
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={() => handleMouseLeave(1)}
          onTouchStart={() => handleMouseEnter(1)}
          onTouchEnd={() => handleMouseLeave(1)}
        >
          <a href="https://www.whitehat4u.com" target="__blank">
            <div className="list-projects__title">
              <WevdevIcon />
              <h2>whitehat4u.com</h2>
            </div>
            <picture>
              <source media="(min-width: 300px)" srcSet="/whitehat4u.jpg" />
              <img
                src="/whitehat4u.jpg"
                alt="White Hat"
                className="list-projects__image"
              />
            </picture>
            <p>
              Whitehat4u is a E-learning platform for developers with knoledge
              in Javascript, CSS and HTML5. Usually courses must be purchased.
              It has features as chat, Like post, comments, and video user
              experience.
            </p>
            <ButtonMain title="Go to Whitehat4u"></ButtonMain>
          </a>
          <Tooltip
            position="default"
            display={display.find((item) => item.id === 1)?.open || false}
            title="Implemented tools"
          >
            <div>
              <p>
                NonSQL db, Typescript and ReactJS, SVG Animations, Better Chrome
                Experience
              </p>

              <h2>Available for</h2>
              <p>Desktop and mobile browser</p>
            </div>
          </Tooltip>
        </li>

        <li
          onMouseEnter={() => handleMouseEnter(4)}
          onMouseLeave={() => handleMouseLeave(4)}
          onTouchStart={() => handleMouseEnter(4)}
          onTouchEnd={() => handleMouseLeave(4)}
        >
          <div className="list-projects__title">
            <WevdevIcon />
            <h2>JS game</h2>
          </div>
          <picture>
            <source media="(min-width: 300px)" srcSet="/js13k-airbus.png" />
            <img
              src="/js13k-airbus.jpg"
              alt="juan"
              className="list-projects__image"
            />
          </picture>

          <p>
            Tiny javascript game for Desktop and phone version, featuring a
            challengesource: index.html and JS.
          </p>
          <ButtonMain title="js games"></ButtonMain>

          <Tooltip
            position="default"
            display={display.find((item) => item.id === 4)?.open || false}
            title="Implemented tools"
          >
            <div>
              <p>JS, OOP, NodeJS</p>

              <h2>Available for</h2>
              <p>Desktop and mobile browsers</p>
            </div>
          </Tooltip>
        </li>

        <li
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={() => handleMouseLeave(2)}
          onTouchStart={() => handleMouseEnter(2)}
          onTouchEnd={() => handleMouseLeave(2)}
        >
          <a
            href="https://www.npmjs.com/package/@juanpumpkinpie/gsx"
            target="__blank"
          >
            <div className="list-projects__title">
              <WevdevIcon />
              <h2>Component Analytic Library</h2>
            </div>
            <div className="list-projects__packages">
              <NpmLibrary />
              <img src="./logos/gsx-logo.png" alt="" />
            </div>

            <p>
              This library, named Component Analytic Library, is a collection of
              UI components integrated with capabilities of track performance.
              This feature greatly enhances your metrics and analytics
              capabilities. Please note that it is not an open-source library
            </p>
            <ButtonMain title="Download Package Library"></ButtonMain>
          </a>

          <Tooltip
            position="default"
            display={display.find((item) => item.id === 2)?.open || false}
            title="Implemented tools"
          >
            <div>
              <p>
                Lit webcomponent, Encapsulated styles, Storybook show and tell
              </p>

              <h2>Available for</h2>
              <p>React, Vuejs and Angular</p>
            </div>
          </Tooltip>
        </li>
      </ul>
    </ContentStyle>
  );
}

export default Content;
