import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const HeaderStyle = styled("nav")`
  color: var(--white-color);
  font-family: var(--title-font);
  backdrop-filter: blur(10px);

  a {
    text-decoration: none;
  }

  h1 {
    color: var(--text-color);
  }

  nav {
    height: 50px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
  }

  .scroll-watcher {
    height: 2px;
    position: fixed;
    top: 60px;
    background-color: var(--indicator-color);
    width: 100%;
    scale: 0 1;
    animation: scroll-watcher linear;
    animation-timeline: scroll();
    -webkit-animation: scroll();
    transform-origin: left;
  }

  @keyframes scroll-watcher {
    to {
      scale: 1 1;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    &__github {
      background-color: var(--blue-color);
      border: none;
      border-radius: 5px;
      color: var(--white-color);
      font-weight: 600;
      font-family: var(--text-font);
      text-decoration: none;
      padding: 5px 15px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  @media (min-width: ${Screen.web}) {
    margin-left: 70px;
  }
`;
