import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const SideBarStyle = styled("div")`
  display: none;

  @media (min-width: ${Screen.web}) {
    display: block;
    opacity: 0;
    height: 100vh;
    position: fixed; /* or position: sticky */
    top: 0; /* Adjust as needed */
    bottom: 0; /* Adjust as needed */
    left: 0; /* Adjust as needed */
    background-color: var(--black-color);
    padding: 12px;
    width: 45px;
    z-index: 2;
    animation: Appear 1s 3ms ease-in-out forwards;

    /* Transition the opacity property */

    .menu-sideBar {
      padding: 2px;
      margin-top: 5px;
      margin-top: 50px;

      li {
        list-style: none;
      }

      &__item {
        padding: 5px;
        border-radius: 4px;
        background-color: var(--menu-color);
        margin-top: 10px;
      }
    }
  }

  @keyframes Appear {
    to {
      opacity: 1;
    }
  }
`;
