import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const ServiceStyle = styled("div")`
  padding: 20px;
  font-size: 1rem;

  @keyframes roller {
    0% {
      width: 0px;
      background-color: none;
    }

    5% {
      width: 20px;
      background-color: var(--indicator-green);
    }

    50% {
      width: 300px;
    }

    100% {
      width: 0px;
      right: 0;
      background-color: var(--indicator-green);
    }
  }

  @keyframes roller2 {
    0% {
      width: 0px;
      background-color: none;
    }

    5% {
      width: 10px;
      background-color: var(--indicator-green);
    }

    50% {
      width: 500px;
    }

    100% {
      width: 0px;
      background-color: var(--indicator-green);
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .animate {
    position: absolute;
    height: 200px;
    animation: roller 2s cubic-bezier(0.38, 1.15, 0.44, 1) alternate;
  }

  h3,
  .higth {
    color: var(--indicator-green);
  }

  .text-descriptions {
    color: var(--white-color);
    font-size: 0.8rem;
    animation: fade 500ms 1s backwards;
  }

  .space {
    margin-top: 100px;
  }

  @media (min-width: ${Screen.tablet}) {
    padding: 100px;

    section {
      max-width: 300px;
    }
  }

  @media (min-width: ${Screen.web}) {
    padding: 100px;

    section {
      max-width: 500px;
    }

    .animate {
      position: absolute;
      height: 200px;
      animation: roller2 2s cubic-bezier(0.38, 1.15, 0.44, 1) 20ms 1;
    }
  }
`;
