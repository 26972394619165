import { ServiceStyle } from "./ServiceStyle";

const Services = () => {
  return (
    <ServiceStyle>
      <h2>Solution: Apps, Programming Design Systems</h2>
      <section className="space">
        <h3>App Development iOS / Android using React Native:</h3>
        <div className="animate" style={{ animationDelay: "200ms" }}></div>
        <p className="text-descriptions">
          We specialize in creating high-performance, scalable applications
          using ReactJS for web and React Native for mobile. Our expertise
          ensures that your apps are not only visually appealing but also
          function seamlessly across multiple platforms. Whether you need a
          responsive web application or a cross-platform mobile app, our
          development team leverages the power of these cutting-edge
          technologies to deliver top-tier user experiences.
        </p>
      </section>

      <section className="space">
        <h3>Programming Interfaces & Progressive Web Apps:</h3>
        <div className="animate" style={{ animationDelay: "20ms" }}></div>
        <p className="text-descriptions">
          Our development services include building robust programming
          interfaces (APIs) and progressive web applications (PWAs) using the
          latest technologies such as{" "}
          <i className="higth"> ReactJS, VueJS, and vanilla JavaScript</i>. We
          design and develop responsive, fast-loading, and feature-rich
          applications that work on any device, ensuring a smooth user
          experience even in low-bandwidth conditions. Our focus on PWA
          development guarantees that your web apps offer the same functionality
          as native apps, with the added benefits of offline capabilities and
          push notifications.
        </p>
      </section>

      <section className="space">
        <h3>Application Integration with MongoDB, Firebase, and AWS:</h3>
        <div className="animate" style={{ animationDelay: "300ms" }}></div>
        <p className="text-descriptions">
          We provide expert services in integrating your applications with
          powerful backend services like MongoDB, Firebase, and AWS. Whether you
          need real-time data synchronization, secure authentication, cloud
          storage, or scalable server infrastructure, our team ensures seamless
          connectivity between your app and these services. This integration
          enhances your app's functionality, reliability, and scalability,
          giving your users a robust and secure experience.
        </p>
      </section>
    </ServiceStyle>
  );
};

export default Services;
