import React from "react";
import { ButtonMainStyle } from "./buttonMainStyle";

interface ButtonMainProps {
  title: string;
}

function ButtonMain(title: ButtonMainProps) {
  return (
    <ButtonMainStyle>
      <div>{title.title}</div>
    </ButtonMainStyle>
  );
}

export default ButtonMain;
