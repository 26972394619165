import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Services from "Pages/Services";
import Landing from "Pages/Landing";
import Games from "Pages/Games";
import Layout from "Pages/Layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/games" element={<Games />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
