import { Link } from "react-router-dom";
import { SideBarStyle } from "./SideBarStyle";
import { Trophy, Game } from "icons/dashboard";

function SideBar() {
  return (
    <SideBarStyle>
      <div>
        <div>
          <ul className="menu-sideBar">
            <Link to="/services">
              <li className="menu-sideBar__item">
                <Trophy />
              </li>
            </Link>
            <Link to="/games">
              <li className="menu-sideBar__item">
                <Game />
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </SideBarStyle>
  );
}

export default SideBar;
