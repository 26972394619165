import React from "react";
import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

const Footer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;

  margin: auto;
  font-family: var(--title-font);
  padding-bottom: 200px;
  padding-top: 100px;

  h2,
  p {
    color: var(--white-color);
  }

  .active {
    color: var(--indicator-color);
  }

  @media (min-width: ${Screen.web}) {
    padding-left: 70px;
  }
`;

function FooterMenu() {
  return (
    <Footer>
      <h2>Contact</h2>
      <p style={{ fontSize: "0.8rem" }}>
        More project Portfolio asking request on my github profile.
      </p>
    </Footer>
  );
}

export default FooterMenu;
