import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const Linki = styled("div")`
  color: var(--blue-color);
  background-color: var(--light-theme-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  .v-container {
    display: block;
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    overflow: hidden; /* Ensures the video doesn’t overflow */
    position: absolute;
    pointer-events: none;
  }

  .linear-color {
    width: 100%;
    height: 100px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, #476070 20%, transparent);
  }

  .video-krakow {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    pointer-events: auto;
    overflow: hidden;
    position: relative;

    video {
      object-fit: cover;
      object-position: center;
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  .sticky {
    position: sticky;
  }

  .control-data {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
  }

  &[data-theme="dark"] {
    background-color: var(--white-color);
    color: var(--black-color);
    h1,
    p,
    h2 {
      color: var(--black-color);
    }
  }

  .links {
    &__nested {
      color: var(--blue-color);
      background: var(--blue-color);
      padding: 5px;
      border: none;
      position: fixed;
      bottom: 20px;
      right: 20px;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (min-width: ${Screen.tablet}) {
    margin: auto;
  }

  @media (min-width: ${Screen.web}) {
    background: none;
  }

  /* Mobile-specific adjustments */
  @media (max-width: ${Screen.tablet}) {
    .video-krakow {
      /* object-fit: cover;
      object-position: center; */
      height: 100vh; /* Full screen height on mobile */
      /* width: 100%;
      position: absolute;

      pointer-events: none; */
    }
  }
`;

export const StickyWrapper = styled("div")`
  position: sticky;
  top: 0;
  z-index: 2;
`;
