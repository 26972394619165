import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const ContentStyle = styled("ul")`
  list-style: none;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  color: var(--text-color);
  font-size: 0.8rem;

  @media (min-width: ${Screen.tablet}) {
    margin: auto;
  }

  a {
    text-decoration: none;
    color: var(--text-color);
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  li {
    list-style: none;
    position: relative;
  }

  h4 {
    margin-top: 100px;
  }

  .list-projects {
    &__image {
      border-radius: 10px;
      width: 100%;
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      filter: brightness(120%);

      &:hover {
        filter: none;
        margin-left: 10px;
      }
    }

    &__title {
      color: var(--white-color);
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    &__packages {
      background-color: var(--white-color);
      padding: 10px;
      border-radius: 10px;
      transition-timing-function: ease-out;
      transition-duration: 0.3s;
      transition-delay: 200ms;
      img {
        width: 100%;
      }

      &:hover {
        display: block;
        filter: hue-rotate(120deg);
        margin-left: 10px;
      }
    }
  }

  @keyframes ImageMove {
    from {
      margin-left: 0;
    }
    to {
      margin-left: 10px;
    }
  }

  @media (min-width: ${Screen.web}) {
    width: 980px;
    padding-left: 80px;

    .list-projects {
      z-index: 1;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__packages {
        display: flex;
        height: auto;

        &:hover {
          display: flex;
          height: auto;
        }
      }

      &__title {
        height: 40px;
      }

      li {
        max-width: 370px;
        height: 460px;
      }

      a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;
