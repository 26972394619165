import styled from "@emotion/styled";
import { Screen } from "utilities/webScreen";

export const AboutStyle = styled("div")`
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;

  span {
    margin-right: 10px;
  }

  article:nth-child(3) {
    margin-top: 100px;
  }

  .the-matrix {
    margin-top: 0;
    height: 170px;
    font-size: 11px;

    &__item {
      opacity: 0.7;
      color: #a855f7;
      background-image: linear-gradient(45deg, #a4b4fd, #a855f7);
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      animation: Matrix 1s ease-in-out backwards;

      &:before {
        content: "/_";
        color: var(--indicator-color);
        display: inline-block;
      }
    }
  }

  @keyframes Matrix {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }

  .about {
    color: var(--indicator-color);
  }

  article {
    padding-top: 20px;
  }
  a {
    margin-left: 10px;
  }

  .companies-work {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    &__item {
      background-color: var(--white-color);
      padding: 10px;
      border-radius: 5px;
      width: 80px;
      height: 14px;
    }
  }

  .vscode-item {
    padding: 10px;
    background-color: var(--black-color);
    border-radius: 10px;
    position: relative;
    margin-bottom: 10px;
  }

  @media (min-width: ${Screen.web}) {
    width: 500px;
  }
`;
