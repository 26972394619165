// import React, { useState } from "react";
// import HeaderMenu from "components/header/header";
// import SideBar from "components/sidebar/SideBar";
// import FooterMenu from "components/footerMenu/FooterMenu";
import Content from "components/content/content";
// import { Sun } from "icons/dashboard";
// import { Link, StickyWrapper } from "AppStyle";

const Landing = () => {
  // const [isDarkTheme, setIsDarkTheme] = useState(false);
  // const themeAttribute = isDarkTheme ? "dark" : "light";

  // const toggleTheme = () => {
  //   setIsDarkTheme((prevTheme) => !prevTheme);
  // };

  return (
    <>
      <Content />
    </>
  );
};

export default Landing;
