import styled from "@emotion/styled";
// import { Screen } from "utilities/webScreen";

export const TooltipStyle = styled("div")`
  .Tooltip-bottom {
    position: absolute;
    top: calc(40% + 10px);
    width: 200px;
    background-color: var(--green-wh4u);
    color: var(--white-color);
    box-shadow: black 4px 4px;
    opacity: 0;
    animation: appear 300ms 100ms ease-in-out forwards;
    padding: 10px;
    cursor: pointer;

    box-sizing: content-box;
    gap: 0;
  }

  @keyframes appear {
    0% {
      opacity: 0;
      width: 0px;
    }

    100% {
      opacity: 1;
      width: 200px;
      overflow-y: auto;
    }
  }
`;
