import React, { useState, useEffect } from "react";
import useGetCommits from "utilities/api";

function CommitsList() {
  const [currentIndex, setcurrentIndex] = useState(0);
  const { data, loading } = useGetCommits();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setcurrentIndex((prev) => prev + 1);
    }, 300);

    return () => clearInterval(intervalId);
  }, [data]);

  if (currentIndex === 10) {
    setcurrentIndex(0);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="the-matrix">
        <h4>Activity, github Integration:</h4>
        {data.slice(0, currentIndex + 1).map((item, index) => (
          <div key={index} className="the-matrix__item">
            {item.commit.message}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommitsList;
