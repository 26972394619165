import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import HeaderMenu from "components/header/header";
import SideBar from "components/sidebar/SideBar";
import FooterMenu from "components/footerMenu/FooterMenu";
import { Sun } from "icons/dashboard";
import { Linki, StickyWrapper } from "AppStyle";

const Layout = (children: any) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const themeAttribute = isDarkTheme ? "dark" : "light";

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  return (
    <Linki data-theme={themeAttribute}>
      <StickyWrapper>
        <HeaderMenu />
      </StickyWrapper>

      <section className="control-data">
        <SideBar />
        <Outlet />
      </section>

      <FooterMenu />
      <div onClick={toggleTheme} className="links__nested">
        <Sun />
      </div>

      <div className="v-container">
        <div className="linear-color"></div>
        {/* Ensure the path is absolute or matches your folder structure */}
        <video className="video-krakow" loop autoPlay muted playsInline>
          <source src="./krakowdeveloper-video.mp4" type="video/mp4" />
        </video>
      </div>
    </Linki>
  );
};

export default Layout;
