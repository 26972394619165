import React from "react";
import { AboutStyle } from "./aboutStyle";
import { Hsbc, Volvo, Britenet, Avenga } from "icons/dashboard";
import CommitsList from "components/Commits/Commits";

function AboutMe() {
  return (
    <AboutStyle>
      <div>
        <p>
          <span className="about">Senior Frontend Developer / FullStack</span>
          11 years of experience in the industry. Find here some client's I have
          been working for and also recent project creations.
        </p>
        <p>
          I also cooperate development Apps (iOS/Android) with agroup of 3
          people, fullStack and Ux/Ui.
        </p>

        <article>
          <ul className="companies-work">
            <li className="companies-work__item">
              <img
                src="./logos/Facebook.png"
                alt=""
                style={{ height: "25px", position: "absolute", top: "4px" }}
              />
            </li>
            <li className="companies-work__item">
              <Volvo />
            </li>
            <li className="companies-work__item">
              <Avenga />
            </li>
            <li className="companies-work__item">
              <Hsbc />
            </li>
            <li className="companies-work__item">
              <Britenet />
            </li>
          </ul>
        </article>

        <article>
          <h4>Download</h4>
          <div className="vscode-item">
            VsCode Extension Free:
            <a
              href="https://marketplace.visualstudio.com/items?itemName=whitehat4u.avenga-theme-vs"
              className="about"
              target="__blank"
            >
              Avenga-Theme-vs
            </a>
          </div>
          <div className="vscode-item">
            VsCode Extension Free:
            <a
              href="https://marketplace.visualstudio.com/items?itemName=whitehat4u.cs50-theme-harvard"
              className="about"
              target="__blank"
            >
              cs50-theme-harvard
            </a>
          </div>
        </article>

        <CommitsList />
      </div>
    </AboutStyle>
  );
}

export default AboutMe;
