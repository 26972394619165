import { ServiceStyle } from "./ServiceStyle";

const Games = () => {
  return (
    <ServiceStyle>
      <h2>Game Demo 2024 Javascript</h2>
      <section className="space">
        <h3>Trading Airbuss:</h3>
        <div className="animate" style={{ animationDelay: "200ms" }}></div>
        <p className="text-descriptions">
          Features: play trading different zones collecting Cargo and people.
          Desktop version. Only authorized to render this video game here.
        </p>
      </section>
      <iframe
        title="game"
        src="./game/index.html"
        allow="accelerometer;autoplay;camera;display-capture;encrypted-media;fullscreen;gamepad;geolocation;gyroscope;magnetometer;microphone;midi;payment;picture-in-picture;publickey-credentials-get;sync-xhr;usb;web-share;xr-spatial-tracking"
        width="550px"
        height="350px"
        frameBorder="0"
        scrolling="no"
        style={{ overflow: "hidden" }}
      ></iframe>
    </ServiceStyle>
  );
};

export default Games;
